import Vue from 'vue';
import Vuex from 'vuex';
// import Account from '@/Pages/Account/store';
import feathersVuex from 'feathers-vuex';
import feathersClient from '@/feathers-client.js';
const { service, auth, FeathersVuex } = feathersVuex(feathersClient, { idField: '_id', skipRequestIfExists: true });
Vue.use(Vuex);
Vue.use(FeathersVuex);
export default new Vuex.Store({
    state: {
        size: 'hello',
    },
    plugins: [
        service('icd', {}),
        service('cdb', {}),
        service('odb', {}),
        service('exam', {}),
        service('users', {}),
        service('notes', {}),
        service('media', {}),
        service('brands', {}),
        service('my-team', {}),
        service('service', {}),
        service('profile', {}),
        service('company', {}),
        service('timeline', {}),
        service('products', {}),
        service('invoices', {}),
        service('customers', {
            whitelist: ['$regex', '$options'],
        }),
        service('treatment', {
            whitelist: ['$regex', '$options'],
        }),
        service('suppliers', {
            instanceDefaults: {
                address: {},
                contacts: {},
                enableAddress: true
            }
        }),
        service('my-company', {}),
        service('categories', {}),
        service('appointment', {}),
        service('authManagement', {}),
        service('reports-summary', {}),
        service('appointment-reports', {}),
        service('service-reports', {}),
        service('product-reports', {}),
        service('sales-reports', {}),
        service('customer-reports', {}),
        service('diagnose-objectively', {}),
        service('specialty', {}),
        service('bundle', {}),
        service('xrays', {}),
        service('supplies', {}),
        service('notification', {}),
        service('my-notifications', {}),
        service('documents', {}),
        service('sources', {}),
        service('manipulations', {}),
        service('treatment-plan', {}),
        service('cart', {}),
        service('tags', {}),
        service('transactions', {}),
        service('presets', {
            instanceDefaults: {
                cdb: [],
                odb: [],
                icd: [],
                diagnosis: [],
                diagnosisFinal: [],
                treatments: [],
            }
        }),
        service('subscriptions', {}),
        service('push-notifications', {}),
        service('roles', {}),
        service('permissions', {}),
        service('time-templates', {}),
        service('notification-reports', {}),
        service('expense-reports', {}),
        service('media-summary', {}),
        service('invoices-summary', {}),
        service('tooth', {}),
        service('payment-reports', {}),
        service('balance-reports', {}),
        service('toppers', {}),
        service('services-list', {}),
        service('force-logout', {}),
        service('tasks', {}),
        service('communications', {}),
        service('dispatcher', {}),
        service('dispatch', {}),
        service('labs', {}),
        service('work-place', {}),
        service('pos-cart', {}),
        service('expense', {}),
        service('configurator', {}),
        service('technicians', {}),
        service('technicians-treatment', {}),
        service('technicians-service', {}),
        service('customers-merge', {}),
        service('feedback-questionnaire', {}),
        service('feedback-question', {}),
        service('feedback-answers', {}),
        service('feedback-questionnaires-reports', {}),
        service('text-templates', {}),
        service('sessions', {}),
        service('payment-history', {}),
        service('telegram-integration', {}),
        service('integrations', {}),
        service('onlinepbx-incoming-call', {}),
        service('user-balance-history', {}),
        service('notification-templates', {}),
        service('company-service-language', {}),
        service('customer-cabinet-token', { idField: 'token' }),
        service('whatsapp-integration', {}),
        service('company-registration', {}),
        service('telegram', {}),
        service('user-landing-page', {}),
        auth({
            userService: 'users'
        })
    ],
    // modules: {
    //     account: Account
    // }
});
