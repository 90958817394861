export default {
    path: '/profile',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        {
            path: '', component: () => import('./Index.vue'), props: true,
            children: [
                { path: 'me', name: 'profile.me', component: () => import('./Details.vue') },
                { path: 'security', name: 'profile.security', component: () => import('./Security.vue') },
                { path: 'schedule', name: 'profile.schedule', component: () => import('./Schedule.vue') },
                { path: 'subscription', name: 'profile.subscription', component: () => import('./SubscriptionList.vue') },
                { path: 'social-links', name: 'profile.socialLinks', component: () => import('./SocialLinks.vue') },
            ]
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
